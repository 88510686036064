li.active {
  background-color: #f7f7f7;
  padding: 2rem;
}

li.active a {
  color: #e0405b;
}

.btn-container li {
  padding: 1.125rem 1.25rem;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-left: 1px solid #000;
}
.btn-container .item__description {
  overflow: hidden;
  height: 0;
}
.btn-container .item__description a {
  /* background-color: #fff; */
  display: inline-block;
  color: #000;
  font-size: .75rem;
  border: 1px solid #000;
  margin-top: .5rem;
  font-weight: bold;
  padding: .25rem .5rem;
}
.btn-container li:first-child {
  display: inline-block;
}

.btn-container li:hover {
  background-color: #f7f7f7;
}

.btn-container li:first-child {
  font-weight: bold;
  color: #fff;
  background-color: #000;
  padding: .625rem 1rem;
  font-size: .675rem;
  display: inline-block;
}