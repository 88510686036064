body * {
  box-sizing: border-box;
}

.page-title {
font-size: 2rem;
}
@media screen and (min-width: 768px) {
  .page-title {
font-size: 2.5rem;
width: 90%;
max-width: 1000px;
/* line-height: 1.3; */

}
}
@media screen and (min-width: 992px) {
  .page-title {
font-size: 3.55rem;
}
}
.page-title span {
  display: block;
  margin-bottom: 1rem;
  font-size: .85rem;

}
.page-title + h1 {
  /* margin-left: auto; */
}
.page-title span,
.page-title a {
  opacity: .5;
  text-transform: uppercase;
}

.page-title a {
  display: inline-block;
  font-size: .75rem;

  border-bottom: 1px solid;
  padding-bottom: .25rem;
  vertical-align: text-top;
  margin-left: .5rem;
  margin-top: .875rem;

}
.App-header__hero {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App-header__nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 10;
  text-align: center;
  border-bottom: 1px solid;
  display: flex;
  border-top: 1px solid;
}
.App-header__nav li {
  display: inline-block;
  font-weight: bold;
  padding: .75rem 1rem;
  width: 20%;
  flex-grow: 1;
  font-size: .875rem;
  border-left: 1px solid;
}

.App-header__nav li:first-child {
  border-left: none;
  background-color: #000;
  color: #fff;
  width: 200px;
  flex-grow: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

h1 {
  font-size: 3.25rem;
  margin: 0 0 2rem 0;
}
.section-intro {
  line-height: 1.5;
  font-size: 1.25rem;
  width: 90%;
  max-width: 840px;
}

section {
  position: relative;
  margin-top: 8rem;
}
.step {
  background-color: #f7f7f7;
  text-align: center;
  width: 34px;
  height: 34px;
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  display: none;
  font-size: .75rem;

}
img {
  max-width: 100%;
  /* border: 1px solid #ccc; */
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}
.btn-container {
  width: 400px;
  flex-shrink: 0;
  padding: 0;
  list-style-type: none;
  margin: 0;
  border-bottom: 1px solid;

}

.btn-container p {
  font-size: .8rem;
  line-height: 1.5;
}
.toggleBtn {
  display: block;
  font-weight: bold;
}

.App-footer {
  padding: 10rem 0 5rem 0;
}

.App-footer a {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  margin: 0 20px;
}

.section-header {
  display: flex;
  align-items: center;
}
.section-header p {
  flex-grow: 0;
  margin: 0 2rem 0 0;
}
.section-header h1 {
  margin: 0;
  flex-grow: 1;
  text-align: left;
}

.example-container {
  display: flex;
  align-items: flex-start;
  margin-top: 4rem;
}

.example-image {
  flex-grow: 1;
  margin-top: 2rem;
  padding: 0 0 0 2rem;
}
.example-image__inner {
  width: 100%;
  padding-bottom: 65%;
  background-color: #f7f7f7;
  position: relative;
  overflow: hidden;
}
.example-image__inner:hover div {
  /* top: 10%; */
  /* transition: 300ms ease top; */
}
.example-image__inner div {
  position: absolute;
  box-shadow: 0 0 64px 0 rgb(0 0 0 / 20%);
  width: 50%;
  overflow: hidden;
  height: 100%;
  background: #fcfcfc;
}


.example-image__inner div:first-child {
  left: 8%;
  top: 15%;
  z-index: 5;
}
.is-double .example-image__inner div:nth-child(2) {
  right: 8%;
  bottom: 15%;
}

.example-image__inner .is-empty {
  opacity: 0;
}

.btn-container p:last-child {
  margin-bottom: 0;
}

