.example-image__dot {
  border-radius: 100%;
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;
  background-color: rgba(255,255,255,.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.example-image__dot span {
  display: block;
  width: 10px;
  height: 10px;
  background-color: #e0405b;
  border-radius: 100%;
  z-index: 10;
  cursor: pointer;
}

.example-image__dot a {
  position: absolute;
  top: -100%;
  left: 80%;
  font-size: 10px;
  text-transform: uppercase;
  color: #fff;
  background-color: #e0405b;
  font-weight: bold;
  white-space: nowrap;
  padding: .5rem .65rem;
  opacity: 0;
  transition: 300ms ease opacity, 200ms ease top;

}

.example-image__dot:hover a {
  opacity: 1;
  top: -80%;
  transition: 300ms ease opacity, 200ms ease top;

}


.is-triple .example-image__inner div {
  height: 0;
  padding-bottom: 33%;
  left: 8%;
  top: 15%;
  /* z-index: 5; */
}

.is-triple .example-image__inner div:nth-child(2) {
  z-index: 2;
}
.is-triple .example-image__inner div:nth-child(2) {
  z-index: 5;
}

.is-triple .example-image__inner div:nth-child(3) {
  z-index: 10;
}